import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { SvgIconProps } from "@mui/material";

import { ReactComponent as ArrowLeft } from "../../icons/arrow-left-large.svg";
import { ReactComponent as ArrowRight } from "../../icons/arrow-right.svg";
import { ReactComponent as BellIcon } from "../../icons/bell.svg";
import { ReactComponent as DeviceIcon } from "../../icons/device.svg";
import { ReactComponent as EnvelopeIcon } from "../../icons/envelope.svg";

type IconName = keyof typeof icons;

type IconProperties = SvgIconProps & {
  name: IconName;
  size?: IconSize;
};

type IconSize = "inherit" | "lg" | "md" | "sm";

const customIcons = {
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  bell: BellIcon,
  device: DeviceIcon,
  envelope: EnvelopeIcon,
};

const muiIcons = {
  accessTime: AccessTimeIcon,
  add: AddIcon,
  cancelPresentation: CancelPresentationIcon,
  checkCircleOutline: CheckCircleOutlineIcon,
  check: CheckIcon,
  chevronLeft: ChevronLeftIcon,
  close: CloseIcon,
  contentCopy: ContentCopyIcon,
  contentCopyOutlined: ContentCopyOutlinedIcon,
  dashboard: DashboardIcon,
  logout: LogoutIcon,
  menu: MenuIcon,
  moreTime: MoreTimeIcon,
  phoneOutlined: PhoneOutlinedIcon,
  priorityHigh: PriorityHighIcon,
  schedule: ScheduleIcon,
};

const icons = { ...customIcons, ...muiIcons };

const getFontSize = (size: IconSize) => {
  const fontSizes = {
    inherit: "inherit",
    lg: "large",
    md: "medium",
    sm: "small",
  } as const;
  return fontSizes[size];
};

function Icon({ name, size = "md", ...iconProperties }: IconProperties) {
  const IconElement = icons[name];

  return (
    <IconElement
      {...iconProperties}
      fontSize={getFontSize(size)}
      style={{ fill: "currentcolor" }}
    />
  );
}

export type { IconName };
export { Icon };
