import { useLingui } from "@lingui/react";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUrls = () => {
  const {
    i18n: { locale },
  } = useLingui();

  return {
    bookings: {
      new: "/bookings/new",
    },
    consent: {
      confirmationsAndReminders: "/consent/confirmations-and-reminders",
      index: "/consent",
      welcomeBack: "/consent/welcome-back",
    },
    index: "/",
    logout: "/logout",
    nhsUpgrade: "/nhs-upgrade",
    onboarding: {
      consent: "/onboarding/consent",
      email: "/onboarding/email",
      index: "/onboarding",
      notificationType: "/onboarding/notification-type",
      phoneNumber: "/onboarding/phone-number",
    },
    privacyPolicy:
      locale === "sv"
        ? "https://www.zymego.com/sv/integritetspolicy"
        : "https://www.zymego.com/privacy-policy",
    termsOfService:
      locale === "sv"
        ? "https://www.zymego.com/sv/anvandarvillkor"
        : "https://www.zymego.com/terms-of-service",
    zymego: {
      index:
        locale === "sv"
          ? "https://www.zymego.com/?r=0"
          : "https://www.zymego.com/en",
    },
  };
};
