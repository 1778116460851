import { Trans } from "@lingui/macro";
import { Grid, Typography } from "@mui/material";
import { createContext, ReactNode, useContext } from "react";
import { FieldValues, UseFormHandleSubmit } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button } from "../components/button/button";
import { IconName } from "../components/icon/icon";
import { IconCircle } from "../components/icon-circle/icon-circle";
import { Stepper } from "../components/stepper/stepper";
import { isFeatureEnabled } from "../feature-flags";
import { Layout } from "../layout";
import { useUrls } from "../urls";
import { GetCurrentPatientQuery } from "./get-current-patient.graphql";

type OnboardingStep = "email" | "phoneNumber" | "notificationType" | "consent";

const onboardingSteps: OnboardingStep[] = [
  "email",
  "phoneNumber",
  isFeatureEnabled("consentForConfirmationsAndReminders")
    ? "consent"
    : "notificationType",
];

const Context = createContext({
  hideOtherSteps: false,
  isValid: false,
  step: "email" as OnboardingStep,
});

const getNextOnboardingStep = (
  patient: GetCurrentPatientQuery["patient"],
): OnboardingStep | undefined => {
  if (!patient?.email) {
    return "email";
  }

  if (!patient?.phoneNumber) {
    return "phoneNumber";
  }

  if (isFeatureEnabled("consentForConfirmationsAndReminders")) {
    if (patient?.consentForConfirmationsAndReminders == null) {
      return "consent";
    }
  } else {
    if (patient?.notificationType == null) {
      return "notificationType";
    }
  }
};

const useOnboardingStepper = () => {
  const context = useContext(Context);
  const urls = useUrls();

  const stepIndex = onboardingSteps.indexOf(context.step);
  const nextStep = onboardingSteps[stepIndex + 1];
  const previousStep = onboardingSteps[stepIndex - 1];

  return {
    nextStepUrl: nextStep ? urls.onboarding[nextStep] : urls.index,
    previousStepUrl: previousStep ? urls.onboarding[previousStep] : undefined,
    stepIndex,
  };
};

function OnboardingView({
  children,
  hideOtherSteps,
  isValid,
  step,
}: {
  children: ReactNode;
  hideOtherSteps?: boolean;
  isValid: boolean;
  step: OnboardingStep;
}) {
  return (
    <Context.Provider
      value={{ hideOtherSteps: Boolean(hideOtherSteps), isValid, step }}
    >
      <OnboardingViewContent>{children}</OnboardingViewContent>
    </Context.Provider>
  );
}

function OnboardingViewContent({ children }: { children: ReactNode }) {
  const context = useContext(Context);
  const { previousStepUrl } = useOnboardingStepper();

  return (
    <Layout
      headerColor="light"
      headerHideContact
      headerOnBackClick={context.hideOtherSteps ? undefined : previousStepUrl}
    >
      <Grid
        alignItems="stretch"
        container
        direction="column"
        flexGrow={1}
        height="100%"
        justifyContent="space-between"
        padding={3}
        spacing={3}
        textAlign="center"
      >
        {children}
      </Grid>
    </Layout>
  );
}

OnboardingView.ActionText = function OnboardingViewActionText({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Grid item>
      <Typography
        align="center"
        color="secondary"
        fontSize={18}
        fontWeight={700}
      >
        {children}
      </Typography>
    </Grid>
  );
};

OnboardingView.DescriptionText = function OnboardingViewDescriptionText({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Grid item>
      <Typography
        align="center"
        color="secondary"
        fontSize={21}
        fontWeight={700}
        marginBottom={2}
      >
        <Trans>Welcome!</Trans>
      </Typography>
      <Typography align="center" fontSize={17} fontWeight={500}>
        {children}
      </Typography>
    </Grid>
  );
};

OnboardingView.Form = function OnboardingViewForm<
  FormValues extends FieldValues,
>({
  children,
  handleSubmit,
  isDirty,
  isSubmitting,
  onSubmit,
}: {
  children: ReactNode;
  handleSubmit: UseFormHandleSubmit<FormValues>;
  isDirty: boolean;
  isSubmitting: boolean;
  onSubmit: (values: FormValues) => Promise<void>;
}) {
  const context = useContext(Context);
  const navigate = useNavigate();
  const { nextStepUrl } = useOnboardingStepper();

  return (
    <Grid item>
      <form
        noValidate
        onSubmit={handleSubmit(async (values) => {
          await onSubmit(values);
          navigate(nextStepUrl);
        })}
      >
        {children}
        <Button.Next
          disabled={!context.isValid && !isDirty}
          loading={isSubmitting}
        />
      </form>
    </Grid>
  );
};

OnboardingView.Icon = function OnboardingViewIcon({
  icon,
}: {
  icon: IconName;
}) {
  const context = useContext(Context);
  return <IconCircle colorized={context.isValid} icon={icon} />;
};

OnboardingView.Stepper = function OnboardingViewStepper() {
  const context = useContext(Context);
  const { stepIndex } = useOnboardingStepper();

  if (context.hideOtherSteps) {
    return null;
  }

  return (
    <Stepper
      isValid={context.isValid}
      stepIndex={stepIndex}
      stepsCount={onboardingSteps.length}
    />
  );
};

export { getNextOnboardingStep, OnboardingView };
