import { t } from "@lingui/macro";
import { Navigate, Outlet } from "react-router-dom";

import { getNextOnboardingStep } from "../onboarding/onboarding-view";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";

function PrivateRouteLogIn() {
  const {
    data: { patient } = {},
    error,
    loading,
  } = useGetCurrentPatientQuery();

  if (loading || !patient) {
    return null;
  }

  if (error) {
    throw new Error(t`Something went wrong`);
  }

  if (getNextOnboardingStep(patient)) {
    return <Navigate to="onboarding" />;
  }

  return <Outlet />;
}

export { PrivateRouteLogIn };
