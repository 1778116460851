import { t, Trans } from "@lingui/macro";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormHelperText,
  FormLabel,
  Link as MuiLink,
  TextField,
  Typography,
} from "@mui/material";
import { PatientNotificationTypes } from "graphql-let/__generated__/__types__";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { useCancellationRequestMutation } from "../cancel/cancellation-request.graphql";
import { Radio } from "../components/radio/radio";
import { DialogBottom } from "../dialog-bottom";
import { getGeographyConfig } from "../geographies";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { useGetTimeslotAndCurrentPatientQuery } from "../reschedule/get-timeslot-and-current-patient.graphql";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors, theme } from "../theme";
import { useUpdatePatientMutation } from "./update-patient.graphql";

interface FormValues {
  email: string;
  notificationType: PatientNotificationTypes;
  phoneNumber?: string;
}

function ManagePatientInformationInWishlist() {
  const backgroundLocation = useBackgroundLocation();
  const { appointmentId = "", isCancelled = "" } = useParams();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(true);

  const { countryCode } = getGeographyConfig();

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    getValues,
  } = useForm<FormValues>();

  const {
    error,
    loading,
    data: { timeslot: appointment, patient } = {},
  } = useGetTimeslotAndCurrentPatientQuery({
    variables: { timeslotId: appointmentId },
  });

  const [cancelAppointmentRequest, { loading: cancellationLoading }] =
    useCancellationRequestMutation();

  const [
    updatePatientRequest,
    { error: updatePatientError, loading: updatePatientLoading },
  ] = useUpdatePatientMutation();

  function handleDialogClose() {
    setIsDialogOpen(false);
  }

  if (loading && (!appointment || !patient)) {
    return null;
  }

  if (error || (!loading && !appointment) || !patient) {
    throw new Error(t`Something went wrong while fetching your information.`);
  }
  if (updatePatientError) {
    throw new Error(
      t`Something went wrong and we couldn't update your contact information`,
    );
  }

  const onSubmit: SubmitHandler<FormValues> = async ({
    email,
    notificationType,
    phoneNumber: formattedPhoneNumber = "",
  }) => {
    // If empty string is passed, it will be converted to undefined
    if (!patient) {
      return;
    }

    const phoneNumber =
      parsePhoneNumberFromString(formattedPhoneNumber)?.number ?? null;

    try {
      await updatePatientRequest({
        variables: {
          email,
          notificationType,
          phoneNumber,
          patientId: patient.id,
        },
      });
    } catch {
      // Ignore
    }

    if (isCancelled === "true") {
      const { data: cancellationData } = await cancelAppointmentRequest({
        variables: { appointmentId },
      });

      navigate(
        `../status/${cancellationData?.cancelAppointment?.cancelAppointmentRequest?.id}`,
        {
          replace: true,
        },
      );

      return;
    }

    navigate("../status", {
      replace: true,
    });
  };

  return (
    <DialogBottom
      onClose={handleDialogClose}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        {theme.direction === "ltr" ? (
          <ArrowBackIos fontSize="small" />
        ) : (
          <ArrowForwardIos fontSize="small" />
        )}
        <MuiLink
          color={colors.blue}
          onClick={() => navigate(-1)}
          underline="none"
        >
          <Trans>Back</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          paddingBottom: {
            xs: 1,
            sm: 2,
          },
          paddingX: { xs: 2, sm: 2 },
        }}
      >
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              paddingX: {
                xs: 1,
                sm: 2,
              },
            }}
          >
            <Typography align="center" variant="h4">
              <Trans>Confirmation will be sent to</Trans>
            </Typography>
            {isCancelled === "true" ? (
              <Typography
                align="center"
                sx={{ opacity: "60%" }}
                variant="subtitle2"
              >
                <Trans>
                  If a timeslot that suits you becomes available, we'll send you
                  a booking confirmation
                </Trans>
              </Typography>
            ) : (
              <Typography
                align="center"
                sx={{ opacity: "60%" }}
                variant="subtitle2"
              >
                <Trans>
                  You'll get offers of time slots according to your request.
                </Trans>
              </Typography>
            )}
            <Typography
              align="center"
              sx={{ opacity: "60%" }}
              variant="subtitle2"
            >
              <Trans>Please confirm your contact details</Trans>
            </Typography>

            <Box sx={{ marginBottom: "15px" }}>
              <Controller
                control={control}
                defaultValue={patient?.email ?? ""}
                name="email"
                render={({ field }) => (
                  <TextField
                    sx={{ direction: "ltr" }}
                    {...field}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors.email)}
                    fullWidth={true}
                    helperText={errors.email?.message}
                    label={t`Email`}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                    }}
                    size="medium"
                    type="email"
                  />
                )}
                rules={{
                  required: t`Email is required.`,
                  pattern: {
                    value: /^.+@.+$/,
                    message: t`Please provide a valid email address.`,
                  },
                }}
              />
              <Controller
                control={control}
                defaultValue={patient.phoneNumber ?? ""}
                name="phoneNumber"
                render={({ field, fieldState }) => {
                  return (
                    <MuiTelInput
                      {...field}
                      defaultCountry={countryCode}
                      error={Boolean(fieldState.error)}
                      forceCallingCode
                      fullWidth
                      helperText={errors.phoneNumber?.message}
                      label={t`Phone Number`}
                      sx={{ direction: "ltr" }}
                    />
                  );
                }}
                rules={{
                  validate(value?: string) {
                    const notificationType = getValues("notificationType");

                    if (notificationType === "SMS" && !value) {
                      return t`Phone number is required.`;
                    }
                    if (
                      notificationType === "SMS" &&
                      value &&
                      !matchIsValidTel(value)
                    )
                      return t`Please provide the phone number in a valid format, example: +46701234567`;
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <FormLabel
                sx={{
                  position: "absolute",
                  fontSize: "0.93rem",
                  color: colors.zymegoGreen,
                  left: 0,
                  top: 0,
                  transform: "translateY(-50%) scale(.9)",
                  backgroundColor: "white",
                  padding: "0 0.3rem",
                  margin: "0 0.5rem",
                  transformOrigin: "left top",
                  pointerEvents: "none",
                }}
              >
                <Trans>I want notifications by</Trans>
              </FormLabel>
              <Box
                sx={{
                  border: "2px solid #E6E6E6",
                  borderRadius: "6px",
                  padding: "1rem 2rem",
                }}
              >
                <Controller
                  control={control}
                  defaultValue={
                    patient.notificationType ?? ("" as PatientNotificationTypes)
                  }
                  name="notificationType"
                  render={({ field }) => (
                    <Radio.Group {...field} aria-label="Notification">
                      <Radio label={t`SMS`} value="SMS" />
                      <Radio label={t`Email`} value="EMAIL" />
                    </Radio.Group>
                  )}
                  rules={{
                    required: t`Notification type is required.`,
                  }}
                />
              </Box>
              <FormHelperText error>
                {errors.notificationType?.message}
              </FormHelperText>
            </Box>

            <LoadingButton
              data-cy="cancellation-confirm-contact-information-button"
              endIcon={
                theme.direction === "ltr" ? (
                  <ArrowRight style={{ fill: "currentColor" }} />
                ) : (
                  <ArrowLeft style={{ fill: "currentcolor" }} />
                )
              }
              fullWidth
              loading={
                isSubmitting || updatePatientLoading || cancellationLoading
              }
              loadingPosition="end"
              size="large"
              type="submit"
              variant="contained"
            >
              <Trans>Confirm</Trans>
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </DialogBottom>
  );
}

export { ManagePatientInformationInWishlist };
