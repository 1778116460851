import { t } from "@lingui/macro";
import { useEffect } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { isFeatureEnabled } from "../feature-flags";
import { AuthLevel, useAuthLevel } from "../nhs/user-auth-level";
import { useUrls } from "../urls";
import { AppUserState } from "../user/app-user";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";

function PrivateRouteConsent({
  requiredAuthLevel,
}: {
  requiredAuthLevel: AuthLevel;
}) {
  const { loading: isAuthLevelLoading, satisfiesAuthLevel } = useAuthLevel();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParameters] = useSearchParams();
  const urls = useUrls();

  const {
    data: { patient } = {},
    error,
    loading,
  } = useGetCurrentPatientQuery();

  useEffect(() => {
    if (!loading && patient && !patient.consent) {
      navigate({ pathname: "consent", search: location.search });
      return;
    }
  }, [navigate, loading, location.search, patient]);

  useEffect(() => {
    if (
      !isFeatureEnabled("consentForConfirmationsAndReminders") ||
      loading ||
      patient?.consentForConfirmationsAndReminders != null ||
      location.pathname.startsWith(urls.onboarding.index)
    ) {
      return;
    }

    const isReturningPatient = Boolean(patient?.email || patient?.phoneNumber);
    if (isReturningPatient) {
      navigate({ pathname: urls.consent.welcomeBack });
    } else {
      navigate({ pathname: urls.onboarding.index });
    }
  }, [
    loading,
    location.pathname,
    navigate,
    patient?.consentForConfirmationsAndReminders,
    patient?.email,
    patient?.phoneNumber,
    urls.consent.welcomeBack,
    urls.onboarding.index,
  ]);

  if (!isAuthLevelLoading && !satisfiesAuthLevel(requiredAuthLevel)) {
    // We need to include the `clinicName` query parameter in order to
    // show the NHS upgrade view for the new booking flow:
    const clinicName = searchParameters.get("clinicName");
    const state: AppUserState | undefined = clinicName
      ? {
          locationState: {
            historyState: globalThis.history.state,
            pathname: urls.bookings.new,
            search: `?clinicName=${clinicName}`,
          },
        }
      : undefined;
    return <Navigate state={state} to={urls.nhsUpgrade} />;
  }

  if (loading) {
    return null;
  }

  if (error || !patient) {
    throw new Error(t`Something went wrong`);
  }

  if (!patient.consent) {
    // will be redirected from useEffect
    return null;
  }

  return <Outlet />;
}

export { PrivateRouteConsent };
