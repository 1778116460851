import {
  Location,
  Outlet,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import invariant from "tiny-invariant";

import { Consent } from "./consent/consent-dialog";
import { ContactZymego } from "./contact-zymego/contact";
import { ErrorComponent } from "./error";
import { useGetCareUnitsQuery } from "./get-care-units.graphql";
import { AddGuardianshipDialog } from "./guardianship/add-guardianship-dialog";
import { AddGuardianshipStatus } from "./guardianship/add-guardianship-status";
import { SwitchSessionStatus } from "./guardianship/switch-session-status";
import { Layout } from "./layout";
import { AcceptEarlierAppointmentOverlay } from "./new-appointment/accept-earlier-appointment-overlay";
import { BookingInformationNewAppointment } from "./new-appointment/booking-confirmation";
import { BookingTypesProvider } from "./new-appointment/booking-types-context";
import { ContactInformationNewAppointment } from "./new-appointment/contact-information";
import { SelectCaregiver } from "./new-appointment/select-caregiver";
import { SelectedTimeslot } from "./new-appointment/select-timeslot";
import { TypeOfAppointment } from "./new-appointment/select-type";
import { StatusNewAppointment } from "./new-appointment/status";
import { WelcomeNewAppointment } from "./new-appointment/welcome";
import { NewAppointmentLayout } from "./new-appointment-layout";
import { NhsUpgradeView } from "./nhs/upgrade-view";
import { AuthLevel } from "./nhs/user-auth-level";
import { NotFound } from "./not-found";
import { PrivateRouteConsent } from "./protected-routes/consent";
import { BackgroundLocationProvider } from "./router/background-location-context";
import { getConfig } from "./runtime-config";
import { useUrls } from "./urls";
import {
  deepLinkWithProvidedBookingTypeId,
  deepLinkWithProvidedCaregiverId,
  splitByComma,
} from "./utils";

const { ALLOW_GUARDIANSHIP } = getConfig();

const REQUIRED_AUTH_LEVEL: AuthLevel = "medium";

function NewBookingApp() {
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location } | undefined;
  const backgroundLocation = state?.backgroundLocation;

  const [searchParameters] = useSearchParams();
  const urls = useUrls();

  const careUnitUrlFriendlyNames = splitByComma(
    searchParameters.get("clinicName"),
  );

  invariant(careUnitUrlFriendlyNames.length > 0, "clinicName is not provided");

  const {
    error,
    loading,
    data: { careUnits } = {},
  } = useGetCareUnitsQuery({
    variables: { careUnitUrlFriendlyNames },
  });

  if (!careUnits && loading) {
    return null;
  }

  if (!careUnits || error) {
    return (
      <Layout headerColor="light">
        <ErrorComponent component="new-booking-error" />
      </Layout>
    );
  }

  const careUnitNames = careUnits.map((careUnit) => careUnit.name);
  return (
    <>
      {/* Fullscreen routes */}
      <Routes location={backgroundLocation ?? location}>
        <Route element={<Consent />} path="consent" />
        <Route
          element={
            <NhsUpgradeView
              onBackClick={() => {
                window.location.href = urls.zymego.index;
              }}
              requiredAuthLevel={REQUIRED_AUTH_LEVEL}
            />
          }
          path="nhs-upgrade"
        />
        <Route
          element={
            <PrivateRouteConsent requiredAuthLevel={REQUIRED_AUTH_LEVEL} />
          }
        >
          <Route
            element={
              <BookingTypesProvider>
                <Outlet />
              </BookingTypesProvider>
            }
            path="bookings/new"
          >
            <Route
              element={
                <NewAppointmentLayout>
                  <WelcomeNewAppointment careUnitNames={careUnitNames} />
                </NewAppointmentLayout>
              }
              index
            />
            <Route
              element={
                <NewAppointmentLayout headerOnBackClick>
                  <TypeOfAppointment />
                </NewAppointmentLayout>
              }
              path="type-of-appointment"
            />
            <Route
              element={
                <NewAppointmentLayout
                  headerOnBackClick={
                    !deepLinkWithProvidedBookingTypeId(location)
                  }
                  showBookingTypeIfAvailable={true}
                >
                  <SelectCaregiver />
                </NewAppointmentLayout>
              }
              path="select-caregiver"
            />
            <Route
              element={
                <NewAppointmentLayout
                  headerOnBackClick={!deepLinkWithProvidedCaregiverId(location)}
                  showBookingTypeIfAvailable={true}
                >
                  <SelectedTimeslot />
                </NewAppointmentLayout>
              }
              path="select-timeslot"
            />
            <Route
              element={
                <NewAppointmentLayout headerOnBackClick>
                  <ContactInformationNewAppointment />
                </NewAppointmentLayout>
              }
              path="contact-information"
            />
            <Route
              element={
                <NewAppointmentLayout headerOnBackClick>
                  <BookingInformationNewAppointment />
                </NewAppointmentLayout>
              }
              path="booking-information"
            />

            <Route path="status/:requestId">
              <Route
                element={
                  <NewAppointmentLayout>
                    <StatusNewAppointment />
                  </NewAppointmentLayout>
                }
                index
              />
            </Route>

            {ALLOW_GUARDIANSHIP && (
              <>
                <Route
                  element={<AddGuardianshipStatus />}
                  path="guardianship/:id/status"
                />
                <Route
                  element={<SwitchSessionStatus />}
                  path="switch-session/:id/status"
                />
              </>
            )}
          </Route>
        </Route>
        <Route element={<NotFound />} path="*" />
      </Routes>

      {/* Bottom dialog routes */}
      {backgroundLocation ? (
        <BackgroundLocationProvider backgroundLocation={backgroundLocation}>
          <Routes>
            <Route
              element={
                <PrivateRouteConsent requiredAuthLevel={REQUIRED_AUTH_LEVEL} />
              }
            >
              <Route path={"bookings/new"}>
                {ALLOW_GUARDIANSHIP ? (
                  <Route
                    element={
                      <AddGuardianshipDialog
                        careUnitUrlFriendlyName={careUnitUrlFriendlyNames[0]} // For the moment it should only handling single care unit
                      />
                    }
                    path="add-guardianship"
                  />
                ) : (
                  ""
                )}
                <Route path="status/:requestId">
                  <Route
                    element={<AcceptEarlierAppointmentOverlay />}
                    path="accept"
                  />
                </Route>
              </Route>
              <Route element={<ContactZymego />} path="contact" />
            </Route>
          </Routes>
        </BackgroundLocationProvider>
      ) : null}
    </>
  );
}

export { NewBookingApp };
