import { CountryCode } from "libphonenumber-js";

import { getConfig, GetConfigOptions } from "./runtime-config";

type Geography = (typeof GEOGRAPHIES)[number];

type GeographyConfig = {
  countryCode: CountryCode;
  defaultLocale: LocaleName;
  enabledFeatures: {
    authLevels: boolean;
    lateAppointmentChanges: boolean;
  };
  locales: LocaleName[];
};

type LocaleName = "ar" | "cy" | "en" | "sv";

const GEOGRAPHIES = ["SE", "UK"] as const;

const geographyConfigs: Record<Geography, GeographyConfig> = {
  SE: {
    countryCode: "SE",
    defaultLocale: "sv",
    enabledFeatures: {
      authLevels: false,
      lateAppointmentChanges: true,
    },
    locales: ["ar", "en", "sv"],
  },
  UK: {
    countryCode: "GB",
    defaultLocale: "en",
    enabledFeatures: {
      authLevels: true,
      lateAppointmentChanges: false,
    },
    locales: ["en", "cy"],
  },
};

const getGeographyConfig = (options?: GetConfigOptions): GeographyConfig => {
  const { GEOGRAPHY } = getConfig(options);
  return geographyConfigs[GEOGRAPHY];
};

export { GEOGRAPHIES, getGeographyConfig };
export type { Geography, GeographyConfig, LocaleName };
