import { t, Trans } from "@lingui/macro";
import { ArrowForward } from "@mui/icons-material";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSessionStorageValue } from "@react-hookz/web";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { Control, Controller, SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { ConsentForConfirmationsAndRemindersField } from "../consent/consent-for-confirmations-and-reminders-field";
import { useDateFormatter } from "../datetime/use-date-formatter";
import { ErrorComponent } from "../error";
import { isFeatureEnabled } from "../feature-flags";
import { getGeographyConfig } from "../geographies";
import { NewAppointmentLayout } from "../new-appointment-layout";
import { useAuthLevel } from "../nhs/user-auth-level";
import { formatPatientIdentifier } from "../patient/identifier";
import { useFormPersist } from "../react-hook-form-persist";
import { getConfig } from "../runtime-config";
import { colors } from "../theme";
import { getIdentifierPlaceholder } from "../utils";
import { useNewAppointmentAndUpdatePatientMutation } from "./create-appointment-and-update-patient.graphql";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";
import { useGetTimeslotQuery } from "./get-timeslot.graphql";

interface FormValues {
  consentForConfirmationsAndReminders?: "false" | "true";
  email: string;
  phoneNumber: string;
}

interface PersistData {
  reason?: string;
}

const { AUTH_IDENTITY_PROVIDER, GEOGRAPHY } = getConfig();

function ContactInformationNewAppointment() {
  const { authLevel } = useAuthLevel();
  const { formatDate, formatTime } = useDateFormatter();
  const { countryCode } = getGeographyConfig();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParameters] = useSearchParams();

  const timeslotId = searchParameters.get("timeslotId");
  invariant(timeslotId, "timeslot Id is not provided");
  const sessionStorageValue = useSessionStorageValue<PersistData>(
    "patient-information",
  );
  const reason = sessionStorageValue.value?.reason ?? "";

  const shortlinkIdent = searchParameters.get("link");

  const {
    loading,
    error,
    data: { timeslot: selectedTimeslot } = {},
  } = useGetTimeslotQuery({
    variables: { timeslotId },
  });

  const [
    createAppointmentAndUpdatePatient,
    { loading: mutationLoading, error: mutationError },
  ] = useNewAppointmentAndUpdatePatientMutation();

  const {
    data: { patient } = {},
    loading: currentPatientLoading,
    error: currentPatientError,
  } = useGetCurrentPatientQuery();
  const {
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    mode: "onChange",
  });

  useFormPersist({ key: "contact-information", watch, setValue });

  if (error || !patient || !timeslotId || mutationError) {
    return (
      <NewAppointmentLayout>
        <ErrorComponent component="new-booking-error" />
      </NewAppointmentLayout>
    );
  }

  if (currentPatientLoading && !patient) {
    return null;
  }

  if (
    (!selectedTimeslot && !loading) ||
    currentPatientError ||
    !patient ||
    mutationError ||
    !timeslotId
  ) {
    return <ErrorComponent component="new-booking-error" />;
  }

  const bookingTypeName =
    selectedTimeslot?.bookingType?.visibleName ??
    selectedTimeslot?.bookingType?.name;

  const onSubmit: SubmitHandler<FormValues> = async ({
    email,
    consentForConfirmationsAndReminders,
    phoneNumber: formattedPhoneNumber = "",
  }) => {
    //If empty string is passed, it will be converted to undefined

    const phoneNumber =
      parsePhoneNumberFromString(formattedPhoneNumber)?.number ?? undefined;

    try {
      const { data: mutationData } = await createAppointmentAndUpdatePatient({
        variables: {
          consentForConfirmationsAndReminders:
            consentForConfirmationsAndReminders
              ? consentForConfirmationsAndReminders === "true"
              : undefined,
          timeslotId,
          reason,
          patientId: patient.id,
          email,
          phoneNumber,
          shortlinkIdent,
        },
      });
      navigate(
        {
          pathname: `../status/${mutationData?.createAppointment?.createAppointmentRequest?.id}`,
          search: location.search,
        },
        { replace: true },
      );
    } catch (caughtError) {
      console.error(caughtError);
      // but ignore, status page will show error
    }
  };

  const patientDisplayIdentifier = formatPatientIdentifier(patient.identifier, {
    maskIdentifier: authLevel !== "high",
  });

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{ flex: "auto", display: "flex" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        justifyContent="space-between"
        sx={{
          paddingX: {
            xs: 2,
            sm: 3,
          },
          paddingBottom: {
            xs: 2,
            sm: 3,
          },
        }}
      >
        <Box>
          <Box sx={{ mb: 2 }}>
            {selectedTimeslot ? (
              <Box>
                <Typography align="center" fontSize="21px" fontWeight="500">
                  {selectedTimeslot.careUnit && (
                    <Trans>{selectedTimeslot.careUnit.name}</Trans>
                  )}
                </Typography>
                <Typography
                  align="center"
                  sx={{
                    ":first-letter": {
                      textTransform: "uppercase",
                    },
                  }}
                  variant="h6"
                >
                  {formatDate(
                    new Date(selectedTimeslot.startAtInCareUnitsTimezone),
                  )}{" "}
                  &bull;{" "}
                  {formatTime(
                    new Date(selectedTimeslot.startAtInCareUnitsTimezone),
                  )}
                </Typography>
                <Typography align="center" fontWeight="medium" variant="body2">
                  <Trans>with</Trans> {selectedTimeslot.caregiver?.name}
                  {bookingTypeName ? `, ${bookingTypeName}` : ""}
                </Typography>
              </Box>
            ) : (
              <Typography
                color={colors.zymegoWarmGreen}
                fontSize="21px"
                fontWeight="700"
              >
                <Trans>Submit contact details</Trans>
              </Typography>
            )}
          </Box>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth={true}
            inputProps={{ readOnly: true }}
            label={
              AUTH_IDENTITY_PROVIDER === "nhs"
                ? t`NHS number`
                : t`Personal identity number`
            }
            placeholder={getIdentifierPlaceholder(GEOGRAPHY)}
            size="medium"
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#ADB9AF26",
              },
            }}
            value={patientDisplayIdentifier}
          />
          <Controller
            control={control}
            defaultValue={patient.phoneNumber ?? ""}
            name="phoneNumber"
            render={({ field, fieldState }) => {
              return (
                <MuiTelInput
                  sx={{ direction: "ltr" }}
                  {...field}
                  defaultCountry={countryCode}
                  error={Boolean(fieldState.error)}
                  forceCallingCode
                  fullWidth
                  helperText={errors.phoneNumber?.message}
                  label={t`Mobile phone`}
                />
              );
            }}
            rules={{
              required: t`Phone number is required`,
              validate(value) {
                if (matchIsValidTel(value)) {
                  return true;
                }

                return t`Please provide the phone number in a valid format, example: +46701234567`;
              },
            }}
          />
          <Controller
            control={control}
            defaultValue={patient.email ?? ""}
            name="email"
            render={({ field }) => (
              <TextField
                sx={{ direction: "ltr" }}
                {...field}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors.email)}
                fullWidth={true}
                helperText={errors.email?.message}
                label={t`Email`}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
                size="medium"
                type="email"
              />
            )}
            rules={{
              required: t`Email is required.`,
              pattern: {
                value: /^.+@.+$/,
                message: t`Please provide a valid email address.`,
              },
            }}
          />
          {isFeatureEnabled("consentForConfirmationsAndReminders") && (
            <ConsentForConfirmationsAndRemindersField
              control={control as unknown as Control}
              showDescription
            />
          )}
        </Box>

        <Button
          disabled={mutationLoading}
          endIcon={mutationLoading ? <CircularProgress /> : <ArrowForward />}
          fullWidth
          type="submit"
          variant="contained"
        >
          <Trans>Next</Trans>
        </Button>
      </Box>
    </form>
  );
}

export { ContactInformationNewAppointment };
