import { t } from "@lingui/macro";
import { Navigate } from "react-router-dom";

import { useUrls } from "../urls";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";
import { getNextOnboardingStep } from "./onboarding-view";

function Onboarding() {
  const { data, error, loading } = useGetCurrentPatientQuery();
  const urls = useUrls();

  if (loading) {
    return null;
  }

  if (error || !data?.patient) {
    throw new Error(t`Something went wrong`);
  }

  const nextOnboardingStep = getNextOnboardingStep(data.patient);
  const to = nextOnboardingStep
    ? urls.onboarding[nextOnboardingStep]
    : urls.index;
  return <Navigate replace to={to} />;
}

export { Onboarding };
