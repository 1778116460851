import { t, Trans } from "@lingui/macro";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormLabel,
  Link as MuiLink,
  TextField,
  Typography,
} from "@mui/material";
import { PatientNotificationTypes } from "graphql-let/__generated__/__types__";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { Radio } from "../components/radio/radio";
import { DialogBottom } from "../dialog-bottom";
import { getGeographyConfig } from "../geographies";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors, theme } from "../theme";
import { useGetCurrentUserAndAppointmentQuery } from "./get-current-user-and-appointment.graphql";

interface FormValues {
  email: string;
  phoneNumber?: string;
  notificationType: PatientNotificationTypes;
}

function JoinWaitlist() {
  const backgroundLocation = useBackgroundLocation();
  const { countryCode } = getGeographyConfig();
  const navigate = useNavigate();
  const { appointmentId: id } = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState(true);

  invariant(id);

  const {
    data: { patient } = {},
    error: queryError,
    loading: queryLoading,
  } = useGetCurrentUserAndAppointmentQuery({
    variables: {
      appointmentId: id,
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm<FormValues>({
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  if (queryLoading) {
    return null;
  }

  if (queryError) {
    throw new Error(t`Something went wrong`);
  }

  const onSubmit: SubmitHandler<FormValues> = async ({
    email,
    notificationType,
    phoneNumber: formattedPhoneNumber = "",
  }) => {
    // If empty string is passed, it will be converted to undefined

    if (!patient) {
      return;
    }

    const phoneNumber =
      parsePhoneNumberFromString(formattedPhoneNumber)?.number ?? null;

    const search = new URLSearchParams({
      appointmentId: id,
      email,
      notificationType,
      patientId: patient.id,
      ...(phoneNumber && { phoneNumber }),
    }).toString();

    navigate(
      { pathname: "../status", search },
      { state: { backgroundLocation } },
    );
  };

  function handleDialogClose() {
    setIsDialogOpen(false);
  }

  return (
    <DialogBottom
      onClose={handleDialogClose}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        {theme.direction === "ltr" ? (
          <ArrowBackIos fontSize="small" />
        ) : (
          <ArrowForwardIos fontSize="small" />
        )}
        <MuiLink
          color={colors.blue}
          onClick={() => navigate(-1)}
          underline="none"
        >
          <Trans>Back</Trans>
        </MuiLink>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            paddingX: {
              xs: 2,
              sm: 3,
            },
            paddingBottom: {
              xs: 2,
              sm: 3,
            },
          }}
        >
          <Typography paddingBottom={2} textAlign="center" variant="h3">
            <Trans>Confirm contact details</Trans>
          </Typography>

          <Controller
            control={control}
            defaultValue={patient?.email ?? ""}
            name="email"
            render={({ field }) => (
              <TextField
                sx={{ direction: "ltr" }}
                {...field}
                error={Boolean(errors.email)}
                fullWidth
                helperText={errors.email?.message}
                id="outlined"
                label={t`Email`}
                type="email"
              />
            )}
            rules={{
              required: t`Email is required.`,
              pattern: {
                value: /^.+@.+$/,
                message: t`Please provide a valid email address.`,
              },
            }}
          />
          <Controller
            control={control}
            defaultValue={patient?.phoneNumber ?? ""}
            name="phoneNumber"
            render={({ field, fieldState }) => {
              return (
                <MuiTelInput
                  sx={{ direction: "ltr" }}
                  {...field}
                  defaultCountry={countryCode}
                  error={Boolean(fieldState.error)}
                  forceCallingCode
                  fullWidth
                  helperText={errors.phoneNumber?.message}
                  label={t`Phone Number`}
                />
              );
            }}
            rules={{
              validate(value?: string) {
                const notificationType = getValues("notificationType");

                if (notificationType === "SMS" && !value) {
                  return t`Phone number is required.`;
                }
                if (value && !matchIsValidTel(value))
                  return t`Please provide the phone number in a valid format, example: +46701234567`;
              },
            }}
          />
          <Box position="relative" sx={{ marginTop: "20px" }}>
            <FormLabel
              sx={{
                position: "absolute",
                fontSize: "1rem",
                left: 0,
                top: 0,
                transform: "translateY(-50%) scale(.9)",
                backgroundColor: "white",
                padding: "0 0.3rem",
                margin: "0 0.5rem",
                transformOrigin: "left top",
                pointerEvents: "none",
              }}
            >
              <Trans>I want notifications by</Trans>
            </FormLabel>

            <Box
              sx={{
                border: "2px solid #E6E6E6",
                borderRadius: "6px",
                padding: "1rem 2rem",
              }}
            >
              <Controller
                control={control}
                defaultValue={
                  // TODO: Handle null case.
                  patient?.notificationType ?? ("" as PatientNotificationTypes)
                }
                name="notificationType"
                render={({ field }) => (
                  <Radio.Group {...field} aria-label="Notification">
                    <Radio label={t`SMS`} value="SMS" />
                    <Radio label={t`Email`} value="EMAIL" />
                  </Radio.Group>
                )}
              />
            </Box>
          </Box>

          <LoadingButton fullWidth type="submit" variant="contained">
            <Trans>Confirm</Trans>
          </LoadingButton>
        </Box>
      </form>
    </DialogBottom>
  );
}

export { JoinWaitlist };
