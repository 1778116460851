import { isNonEmptyArray } from "@apollo/client/utilities";
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { App } from "./app";
import { AccessDeniedView } from "./auth/access-denied-view";
import { ErrorComponent } from "./error";
import { useGetCareUnitsWithCaregiversQuery } from "./get-care-units-with-caregivers.graphql";
import { NewAppointmentLayout } from "./new-appointment-layout";
import { NewBookingApp } from "./new-booking-app";
import { AppUser, AppUserState } from "./user/app-user";
import { UserProvider } from "./user/user-context";
import { splitByComma } from "./utils";

function AppSelector({
  state,
  user,
}: {
  state: AppUserState | undefined;
  user: AppUser | undefined;
}) {
  const [searchParameters] = useSearchParams();
  const careUnitUrlFriendlyNames = splitByComma(
    searchParameters.get("clinicName"),
  );
  const hasCareUnit = isNonEmptyArray(careUnitUrlFriendlyNames);

  const navigate = useNavigate();
  const location = useLocation();

  const { data, loading, error } = useGetCareUnitsWithCaregiversQuery({
    variables: { careUnitUrlFriendlyNames },
    skip: !hasCareUnit,
  });

  useEffect(() => {
    if (
      !loading &&
      hasCareUnit &&
      !data?.careUnits &&
      location.pathname !== "/"
    ) {
      navigate("/");
    }
  }, [data, hasCareUnit, loading, location, navigate]);

  if (hasCareUnit) {
    if (error) {
      return (
        <NewAppointmentLayout>
          <ErrorComponent component="new-booking-error" />
        </NewAppointmentLayout>
      );
    }
    if (loading && !data) return null;

    if (data?.careUnits) {
      return <NewBookingApp />;
    }
  }

  if (!user) {
    return <AccessDeniedView state={state} />;
  }

  return (
    <UserProvider user={user}>
      <App />
    </UserProvider>
  );
}

export { AppSelector };
