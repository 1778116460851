/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { t, Trans } from "@lingui/macro";
import { Box, Button, Divider, ToggleButton, Typography } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";

import { ErrorComponent } from "../error";
import { Loading } from "../loading";
import { colors } from "../theme";
import { GroupedTimeslots, isWishlistEnabled } from "../utils";
import { DifferentColorForAlternativeCaregivers } from "./different-color-for-alternative-caregivers";
import { useFetchLaterTimeslotsForAppointmentMutation } from "./fetch-timeslots.graphql";
import { useGetAppointmentQuery } from "./get-appointment.graphql";
import {
  GetTimeslotsQuery,
  useGetTimeslotsLazyQuery,
} from "./get-timeslots.graphql";

type Timeslot = NonNullable<GetTimeslotsQuery["timeslots"]>[number];

function Reschedule() {
  const location = useLocation();
  const navigate = useNavigate();
  const { appointmentId: id = "" } = useParams();

  const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot>();
  const { data: { appointment } = {} } = useGetAppointmentQuery({
    variables: {
      id,
    },
  });

  const [
    fetchTimeslots,
    {
      data: mutationData,
      loading: mutationLoading,
      error: mutationError,
      called: mutationCalled,
    },
  ] = useFetchLaterTimeslotsForAppointmentMutation({
    variables: {
      appointmentId: id,
    },
  });

  const [
    getTimeslots,
    {
      loading: queryLoading,
      error: queryError,
      data: queryData,
      called: queryCalled,
    },
  ] = useGetTimeslotsLazyQuery({
    fetchPolicy: "cache-and-network",
    pollInterval: 1000,
  });

  useEffect(() => {
    fetchTimeslots();
  }, [fetchTimeslots]);

  const [hasTimedOut, setHasTimedOut] = useState(false);
  const [caregiverFilter, setCaregiverFilter] = useState<
    "all" | "same" | "other"
  >("all");
  const timeoutHandle = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (mutationData?.fetchTimeslots?.fetchTimeslotsRequest) {
      getTimeslots({
        variables: {
          appointmentId: id,
          fetchTimeslotRequestId:
            mutationData.fetchTimeslots.fetchTimeslotsRequest.id,
        },
      });

      if (timeoutHandle.current) {
        clearTimeout(timeoutHandle.current);
      }

      timeoutHandle.current = setTimeout(
        () => setHasTimedOut(true),
        5 * 60 * 1000,
      );
    }

    return () => clearTimeout(timeoutHandle.current);
  }, [getTimeslots, id, mutationData]);

  useEffect(() => {
    if (selectedTimeslot && !queryData?.timeslots?.includes(selectedTimeslot)) {
      // eslint-disable-next-line unicorn/no-useless-undefined
      setSelectedTimeslot(undefined);
    }
  }, [selectedTimeslot, queryData?.timeslots]);

  const navigateToInfoTimeoutHandler = useRef<ReturnType<typeof setTimeout>>();
  const [haveNavigatedToInfo, setHaveNavigatedToInfo] = useState(false);
  const resolvedPath = useResolvedPath(".");
  const match = useMatch({ path: resolvedPath.pathname, end: true });

  useEffect(() => {
    if (
      match &&
      !haveNavigatedToInfo &&
      appointment &&
      appointment.bookingType &&
      appointment.bookingType.maxRescheduleCount !== null
    ) {
      navigateToInfoTimeoutHandler.current = setTimeout(() => {
        setHaveNavigatedToInfo(true);
        navigate("info", {
          state: { backgroundLocation: location },
          replace: true,
        });
      }, 250);
    }

    return () => {
      if (navigateToInfoTimeoutHandler.current) {
        clearTimeout(navigateToInfoTimeoutHandler.current);
      }
    };
  }, [
    navigate,
    setHaveNavigatedToInfo,
    appointment,
    navigateToInfoTimeoutHandler,
    location,
    match,
    haveNavigatedToInfo,
  ]);

  const loading =
    !mutationCalled ||
    !queryCalled ||
    mutationLoading ||
    queryLoading ||
    (queryData?.fetchTimeslotsRequest
      ? ["QUEUED", "IN_PROGRESS"].includes(
          queryData.fetchTimeslotsRequest.status,
        )
      : false);

  if (
    !loading &&
    (!queryData?.fetchTimeslotsRequest ||
      queryData.fetchTimeslotsRequest.status === "FAILED")
  ) {
    clearTimeout(timeoutHandle.current);
  }

  if (
    queryError ||
    mutationError ||
    queryData?.fetchTimeslotsRequest?.status === "FAILED" ||
    hasTimedOut
  ) {
    return (
      <>
        <ErrorComponent
          errorMessage={t`Unfortunately something went wrong while fetching available time slots.`}
          inline
        />
        <Box
          sx={{
            paddingX: 3,
            marginTop: -3,
            textAlign: "center",
          }}
        >
          <Button
            color="primary"
            component={Link}
            fullWidth
            size="large"
            state={{
              backgroundLocation: `/appointment/${id}/wishlist`,
            }}
            to={"../../wishlist/welcome"}
            variant="contained"
          >
            <Trans>Request days</Trans>
          </Button>
        </Box>
      </>
    );
  }

  let wishlistEnabled = false;
  if (appointment && appointment.careUnit && appointment.bookingType) {
    const { careUnit, bookingType } = appointment;
    wishlistEnabled = isWishlistEnabled({ careUnit, bookingType });
  }

  // Group timeslots so we can easily loop through them in the jsx

  const groupedTimeslots: GroupedTimeslots<Timeslot> = {};

  if (queryData?.timeslots) {
    for (const timeslot of queryData.timeslots) {
      const date = new Date(timeslot.startAtInCareUnitsTimezone);
      const month = format(date, "y-M");
      const day = format(date, "d");
      const time = format(date, "k:mm");

      // Set months
      if (!groupedTimeslots[month]) {
        groupedTimeslots[month] = {
          items: {},
          date: date,
        };
      }
      // Set days
      if (!groupedTimeslots[month]!["items"][day]) {
        groupedTimeslots[month]!["items"][day] = {
          items: {},
          date: date,
        };
      }

      const showTimeConditions = {
        same: timeslot.caregiver?.id === appointment?.caregiver?.id,
        other: timeslot.caregiver?.id !== appointment?.caregiver?.id,
        all: true,
      };

      // Set times
      if (showTimeConditions[caregiverFilter]) {
        groupedTimeslots[month]!["items"][day]!["items"][time] = {
          timeslot,
          date: date,
        };
      }
    }
  }

  return (
    <Box sx={{ marginBottom: "185px" }}>
      {loading ? (
        <Box>
          <Loading
            logo={false}
            text={t`We are fetching available time slots from the patient record system. This may take a while.`}
          />
        </Box>
      ) : (queryData?.timeslots ?? []).length > 0 &&
        Object.keys(groupedTimeslots).length > 0 ? (
        <Box>
          {appointment?.bookingType
            ?.useDifferentColorForAlternativeCaregivers ? (
            <DifferentColorForAlternativeCaregivers
              caregiverFilter={caregiverFilter}
              setCaregiverFilter={setCaregiverFilter}
            />
          ) : (
            <Box
              sx={{
                padding: "16px 40px 0 40px",
                color: colors.zymegoDarkGreen,
                textAlign: "center",
              }}
            >
              <Typography
                component="h6"
                sx={{ color: colors.zymegoGreen }}
                variant="boldInfo"
              >
                <Trans>
                  Select your preferred time and click "Next" to continue
                </Trans>
              </Typography>
            </Box>
          )}
          {Object.keys(groupedTimeslots).map((month) => (
            <Box sx={{ position: "relative", overflowX: "visible" }}>
              <Box
                sx={{
                  background: "white",
                  zIndex: "1",
                  position: "sticky",
                  top: 0,
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  padding: {
                    xs: "8px 16px",
                    sm: "8px 16px",
                  },
                }}
              >
                <Typography
                  component="h6"
                  data-cy="reschedule-time-month"
                  sx={{
                    color: colors.zymegoGreen,
                    ":first-letter": {
                      textTransform: "uppercase",
                    },
                  }}
                  variant="boldSubtitle1"
                >
                  {format(groupedTimeslots[month]!.date, "MMMM")}
                </Typography>
                <Typography
                  data-cy="reschedule-time-year"
                  sx={{ color: colors.zymegoGreen, opacity: 0.35 }}
                  variant="h6"
                >
                  {format(groupedTimeslots[month]!.date, "y")}
                </Typography>
              </Box>
              {Object.keys(groupedTimeslots[month]!.items).map((day) => (
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Box
                    sx={{
                      width: "65px",
                      flexShrink: "0",
                      padding: "8px 16px 8px 16px",
                      ":first-letter": {
                        textTransform: "uppercase",
                      },
                    }}
                  >
                    <Typography
                      component="p"
                      data-cy="reschedule-time-day"
                      sx={{ color: colors.zymegoGreen }}
                      variant="boldBody2"
                    >
                      {format(groupedTimeslots[month]!.items[day]!.date, "E")}
                    </Typography>
                    <Typography
                      component="p"
                      data-cy="reschedule-time-daynum"
                      sx={{ color: colors.zymegoGreen }}
                      variant="boldInfo"
                    >
                      {format(groupedTimeslots[month]!.items[day]!.date, "d")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      padding: "12px 0 0 12px",
                      minHeight: "70px",
                    }}
                  >
                    {Object.keys(
                      groupedTimeslots[month]!.items[day]!.items,
                    ).map((timeslot) => (
                      <ToggleButton
                        component={Button}
                        data-cy="appointment-reschedule-time-button"
                        key={timeslot}
                        onChange={() => {
                          setSelectedTimeslot((currentlySelectedTimeslot) =>
                            currentlySelectedTimeslot ===
                            groupedTimeslots[month]!.items[day]!.items[
                              timeslot
                            ]!.timeslot
                              ? undefined
                              : groupedTimeslots[month]!.items[day]!.items[
                                  timeslot
                                ]!.timeslot,
                          );
                        }}
                        selected={
                          groupedTimeslots[month]!.items[day]!.items[timeslot]!
                            .timeslot.id === selectedTimeslot?.id
                        }
                        sx={{
                          marginX: "8px",
                          marginBottom: "8px",
                          width: "65px",
                          fontSize: "15px",
                          fontWeight: "700",
                          lineHeight: "22px",
                          ...(appointment?.bookingType
                            ?.useDifferentColorForAlternativeCaregivers &&
                            appointment?.caregiver?.id ===
                              groupedTimeslots[month]!.items[day]!.items[
                                timeslot
                              ]!.timeslot.caregiver!.id && {
                              backgroundColor: colors.zymegoPowder,
                              border: `3px solid ${colors.zymegoPowder}`,
                              "&.Mui-selected": {
                                borderColor: colors.zymegoRose,
                              },
                            }),
                          // Undo hover effect on touch devices:
                          "@media (hover: none)": {
                            "&:hover": {
                              backgroundColor: colors.zymegoSpearMint,
                            },
                          },
                        }}
                        type="button"
                        value={
                          groupedTimeslots[month]!.items[day]!.items[timeslot]!
                            .timeslot
                        }
                      >
                        {format(
                          groupedTimeslots[month]!.items[day]!.items[timeslot]!
                            .date,
                          "k:mm",
                        )}
                      </ToggleButton>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            padding: "24px",
          }}
        >
          <Typography
            align="center"
            data-cy="reschedule-no-timeslots-available"
            variant="h4"
          >
            <Trans>There are no timeslots available</Trans>
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            margin: "auto",
            maxWidth: "444px",
            textAlign: "center",
            background: "white",
            boxShadow: "0 -40px 40px white",
          }}
        >
          {!appointment?.onWishlist && !selectedTimeslot && wishlistEnabled ? (
            <>
              <Divider sx={{ marginBottom: 2 }} />

              <Typography
                component="h6"
                sx={{ opacity: "80%", color: colors.zymegoDarkGreen }}
                variant="boldInfo"
              >
                <Trans>Can't find a suitable timeslot?</Trans>
              </Typography>
              <Box
                sx={{
                  paddingX: {
                    xs: 2,
                    sm: 3,
                  },
                }}
              >
                <Button
                  color="secondary"
                  component={Link}
                  disabled={loading}
                  size="small"
                  state={{
                    backgroundLocation: `/appointment/${id}/wishlist`,
                  }}
                  to={"../../wishlist/welcome"}
                  variant="contained"
                >
                  <Trans>Request other days</Trans>
                </Button>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                padding: {
                  xs: 2,
                  sm: 3,
                },
              }}
            >
              <Button
                component={Link}
                data-cy="reschedule-next-button"
                disabled={loading || !selectedTimeslot}
                fullWidth
                size="large"
                state={{ backgroundLocation: location }}
                to={`../${selectedTimeslot?.id}/confirm`}
                type="button"
                variant="contained"
              >
                <Trans>Next</Trans>
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export { Reschedule };
