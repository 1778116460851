import { t, Trans } from "@lingui/macro";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { Controller, useForm } from "react-hook-form";

import { ErrorComponent } from "../error";
import { isFeatureEnabled } from "../feature-flags";
import { getGeographyConfig } from "../geographies";
import { useUser } from "../user/user-context";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";
import { OnboardingView } from "./onboarding-view";
import { useUpdatePatientNotificationTypeMutation } from "./update-patient-notification-type.graphql";
import { useUpdatePatientPhoneNumberMutation } from "./update-patient-phone-number.graphql";

interface FormValues {
  phoneNumber: string;
}

function PhoneNumberInfo() {
  const user = useUser();

  const { countryCode } = getGeographyConfig();

  const {
    data: { patient } = {},
    error,
    loading,
  } = useGetCurrentPatientQuery();

  const [updatePatientNotificationType] =
    useUpdatePatientNotificationTypeMutation();

  const [updatePatientPhoneNumber, { error: mutationError }] =
    useUpdatePatientPhoneNumberMutation();

  const {
    control,
    formState: { isSubmitting, isDirty, isValid },
    handleSubmit,
  } = useForm<FormValues>({
    values: {
      phoneNumber: patient?.phoneNumber ?? user.profile.phone_number ?? "",
    },
  });

  if (loading) {
    return null;
  }

  if (error || mutationError || !patient) {
    return <ErrorComponent inline />;
  }

  return (
    <OnboardingView isValid={isValid} step="phoneNumber">
      <OnboardingView.DescriptionText>
        <Trans>
          First we need your contact information to send booking confirmations.
        </Trans>
      </OnboardingView.DescriptionText>
      <OnboardingView.Stepper />
      <OnboardingView.Icon icon="device" />
      <OnboardingView.ActionText>
        <Trans>Enter your phone number</Trans>
      </OnboardingView.ActionText>
      <OnboardingView.Form
        handleSubmit={handleSubmit}
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onSubmit={async (values): Promise<void> => {
          // If empty string is passed, it will be converted to undefined

          const phoneNumber = parsePhoneNumberFromString(
            values.phoneNumber,
          )?.number;

          if (!phoneNumber) {
            throw new Error(
              "Phone number is not valid, this should not happen",
            );
          }

          await updatePatientPhoneNumber({
            variables: {
              id: patient.id,
              phoneNumber,
            },
          });

          if (isFeatureEnabled("consentForConfirmationsAndReminders")) {
            // TODO: Determine notification type in the backend:
            await updatePatientNotificationType({
              variables: {
                id: patient.id,
                notificationType: "SMS",
              },
            });
          }
        }}
      >
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field, fieldState }) => (
            <MuiTelInput
              sx={{ direction: "ltr" }}
              {...field}
              defaultCountry={countryCode}
              error={fieldState.invalid}
              forceCallingCode
              fullWidth
              helperText={fieldState.error?.message}
              label={t`Phone Number`}
            />
          )}
          rules={{
            required: true,
            validate(value) {
              if (matchIsValidTel(value)) {
                return true;
              }

              return t`Please provide the phone number in a valid format, example: +46701234567`;
            },
          }}
        />
      </OnboardingView.Form>
    </OnboardingView>
  );
}

export { PhoneNumberInfo };
